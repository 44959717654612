
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  watch
} from 'vue';
import PatientHeader from '@/components/Patient/PatientHeader.vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import Pager from '@/components/Commons/Pager.vue';
import UsedFilters from '@/components/Filters/UsedFilters.vue';
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import ResourcesPanel from '@/components/Commons/ResourcesPanel/ResourcesPanel.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import ShareModal from '@/components/Commons/ResourcesPanel/Share/ShareModal/ShareModal.vue';
import PatientAndAdmin from '@/components/PatientAndAdmin/PatientAndAdmin';
import EmptyInfo from '@/components/Commons/EmptyInfo/EmptyInfo.vue';
import {
  FAVOURITES,
  Filter,
  FILTER,
  MODALITIES,
  SEX,
  TAGS,
  TEXT,
  FILTERING
} from '@/store/search/types';
import { useStore as useSearchStore } from '@/store/search';
import { debounce, reverse, sortBy } from 'lodash';
import { day, getDateFromResource, ts } from '@fhir/pixel-commons-js/src/index';
import { getDescriptionFromResource } from '@/components/Patient/PatientUtils';
import { generateUuid } from '@/utils/utils';
import { getAge } from '@/components/FhirComponents/utils/time.utils';
import { Filter as HeaderFilter } from '@/components/Filters/types';
import { CLEAR_SELECTED_STUDIES } from '@/store/studyShare/types';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import useResource from '@/composables/useResource';
import useConsent from '@/composables/useConsent';
import { System } from '@/components/Patient/PatientTags';
import useUser from '@/composables/useUser';
import i18n from '@/lang';

export default defineComponent({
  name: 'ForAdminContent',
  components: {
    EmptyInfo,
    ShareModal,
    BaseButton,
    ResourcesPanel,
    FhirComponentsRouter,
    UsedFilters,
    Pager,
    EcgLoader,
    ExpandablePanel,
    PatientHeader
  },
  setup() {
    const searchStore = useSearchStore();
    const studyShareStore = useStudyShareStore();
    const adminResources = { ...PatientAndAdmin() };
    const userActorConsentsByDocument = useConsent()
      .actorsForDocumentConsentsOnResource;
    const isLinkSharedForPeople = useConsent().isLinkSharedForPeople;
    const { checkIfTagExistInDocument, getLastFavouriteChange } = useResource();
    const refreshTime = Number(localStorage.getItem('refreshTime')) || 0;

    const filter = computed<Filter>(() => searchStore.getters[FILTER]);
    const modalities = computed(() => {
      const ids: string[] = (searchStore.getters[MODALITIES] || []).map(
        (m: any) => m.id
      );
      return window.modalities.filter(
        (f: any) => ids.find((id: string) => id === f.FBID) != null
      );
    });

    const userName = useUser().getUserName();

    const {
      addToFavourites,
      getFavouriteList,
      removeFromFavourites,
      documentExistInFavouriteList
    } = useResource();

    const favouriteList = getFavouriteList();

    const toggledFavourite = (isFavourite: boolean, resource: any) => {
      const type = resource.resourceType;
      const id = resource.id;
      isFavourite ? addToFavourites(id, type) : removeFromFavourites(id, type);
    };

    const clearSelectedStudies = () => {
      studyShareStore.commit(CLEAR_SELECTED_STUDIES);
    };

    const search = debounce(async (): Promise<any> => {
      console.log('search admin', filter.value);
      try {
        const tempResourcesLength: number =
          adminResources.resources.value.length;
        adminResources.activeHeaders.value = [];
        adminResources.resources.value = [];
        adminResources.page.value = 1;
        adminResources.loading.value = true;

        searchStore.commit(FILTERING, true);
        const data = await useResource().searchResources(
          filter.value,
          adminResources.selectedTenant.value
        );

        if (data) {
          const r: any[] = data.flatMap((m: any) => m?.data?.entry ?? []);
          const filteredResource: any[] = [];

          if (r.length !== tempResourcesLength) {
            adminResources.page.value = 1;
          }

          r.forEach((m: any) => {
            const resType = m.resource.resourceType;
            const resId = m.resource.id;
            const duplicate = filteredResource.find(
              resource =>
                resource.resource.resourceType === resType &&
                resource.resource.id === resId
            );
            if (!duplicate) filteredResource.push(m);
          });

          adminResources.resources.value = reverse(
            sortBy(
              filteredResource.map(m => {
                const date = getDateFromResource(m.resource);
                const desc = getDescriptionFromResource(m.resource) || '';
                return {
                  uuid: generateUuid(),
                  ts: Number(ts(date)),
                  date: day(date),
                  description: desc,
                  resource: m.resource,
                  doctor: m.resource.resourceType
                };
              }),
              'ts'
            )
          );
        }

        adminResources.pagedResources.value.forEach(res => {
          res.otherResources.forEach(otherRes => {
            const resource = otherRes.resource;
            const id = otherRes.resource.id;
            const type = otherRes.resource.resourceType;

            const tagExists = checkIfTagExistInDocument(resource, {
              system: System.patientPortalFavouriteSystem,
              code: userName
            });
            if (tagExists) addToFavourites(id, type);
          });
        });
      } catch (e) {
        // if (e.message !== CANCELLED_BY_USER) {
        //   useNotification().addNotification(
        //     NotificationType.ERROR,
        //     '',
        //     i18n.global.t('patient.results.error')
        //   );
        // }
        console.error(i18n.global.t('patient.results.error'), e);
      } finally {
        adminResources.loading.value = false;
        searchStore.commit(FILTERING, false);
      }
    }, 300);

    let refreshInterval: undefined | number;

    onMounted(async () => {
      await useConsent().loadListConsents();
    });

    onUnmounted(() => clearInterval(refreshInterval));

    onUpdated(() => {
      clearInterval(refreshInterval);
      if (refreshTime > 0) {
        refreshInterval = setInterval(() => {
          search();
        }, refreshTime * 1000);
      }
    });

    watch(
      filter,
      v => {
        search();
      },
      { deep: true, immediate: true }
    );

    const usedFilters = computed<HeaderFilter[]>(() => {
      const ret: HeaderFilter[] = [...adminResources.usedFilters.value];

      if (filter.value.favourites != null && filter.value.favourites === true) {
        const filterItem = {
          description: `${i18n.global.t('admin.filters.favouriteResearch')}`,
          remove: () => searchStore.commit(FAVOURITES, null)
        };
        ret.push(filterItem);
      }

      if (filter.value.text != null && filter.value.text.trim().length > 0) {
        const filterItem = {
          description: `${i18n.global.t('admin.filters.text')}: ${
            filter.value.text
          }`,
          remove: () => searchStore.commit(TEXT, null)
        };
        ret.push(filterItem);
      }

      if (filter.value.sex != null && filter.value.sex !== 0) {
        const filterItem = {
          description: `${i18n.global.t('admin.filters.sex')}: ${i18n.global.t(
            `admin.filters.${filter.value.sex === 1 ? 'woman' : 'man'}`
          )}`,
          remove: () => searchStore.commit(SEX, 0)
        };
        ret.push(filterItem);
      }

      if (filter.value.modality != null && filter.value.modality.length > 0) {
        const filterItem = {
          description: `${i18n.global.t(
            'admin.filters.criterion'
          )}: ${filter.value.modality.map(m => m.label).join(', ')}`,
          remove: () => searchStore.commit(MODALITIES, [])
        };
        ret.push(filterItem);
      }

      if (filter.value.tags != null && filter.value.tags.length > 0) {
        const filterItem = {
          description: `${i18n.global.t(
            'admin.filters.tags'
          )}: ${filter.value.tags.map(m => m.name).join(', ')}`,
          remove: () => searchStore.commit(TAGS, null)
        };
        ret.push(filterItem);
      }

      return ret;
    });

    return {
      ...adminResources,
      search,
      usedFilters,
      userActorConsentsByDocument,
      checkIfTagExistInDocument,
      System,
      toggledFavourite,
      userName,
      getAge,
      favouriteList,
      clearSelectedStudies,
      getLastFavouriteChange,
      documentExistInFavouriteList,
      isLinkSharedForPeople
    };
  }
});
