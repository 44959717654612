<template>
  <span
    class="shared-badge"
    :class="{ [`shared-badge-${type}`]: type != null }"
  >
    <font-awesome-icon :icon="ico" aria-hidden="true" class="locker-icon" />
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'SharedBadge',
  props: {
    ico: {
      type: String,
      default: 'lock'
    },
    type: {
      type: String
    }
  }
};
</script>

<style scoped>
.shared-badge {
  background-color: var(--purple);
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 10px;
  color: var(--font-color-alternative);
  display: flex;
  align-items: center;
  justify-content: center;
}
.shared-badge-user {
  background-color: var(--accent);
  color: var(--black);
}
.shared-badge-shared {
  background-color: var(--purple);
  color: var(--font-color-alternative);
}

.locker-icon {
  font-size: 0.775rem;
  margin-right: 5px;
}
</style>
