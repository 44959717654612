export type ShareType = 'email' | 'phone';
export enum ShareEnum {
  'email' = 'email',
  'phone' = 'phone'
}

export interface ReferenceI {
  reference?: string;
  identifier?: {
    system: string;
    value: string | number;
  };
  display?: string;
}

export interface ConsentI {
  resourceType: string;
  id?: string | number;
  status: string;
  patient?: ReferenceI;
  dateTime: string;
  consentor?: ReferenceI;
  provision: {
    type: string;
    period?: {
      start: string;
      end: string;
    };
    actor?: [
      {
        reference: ReferenceI;
      }
    ];
    data?: [
      {
        meaning: string;
        reference: {
          reference: string;
        };
      }
    ];
  };
}

export interface ConsentStateI {
  consentList: [];
  consentListSharedFourUser: any;
}
