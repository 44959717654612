<template>
  <div>
    <PatientHeader @reload="search" :is-smaller="isSmaller" :id="'header-app'">
      <template #default>
        <button
          :aria-label="$t('patient.results.reload')"
          :class="['icon-pat-header', 'flex-layout']"
          @click="search"
        >
          <font-awesome-icon icon="redo" :height="12"></font-awesome-icon>
          <span class="ml">{{ $t('patient.results.reload') }}</span>
        </button>
        <!--
        <button
          v-if="loading"
          :aria-label="$t('patient.results.reload')"
          :class="['icon-pat-header', 'flex-layout']"
          @click="cancel"
        >
          <font-awesome-icon icon="times" :height="12"></font-awesome-icon
          <span class="ml">{{ $t('patient.results.reload') }}</span>
        </button>
        -->
        <UsedFilters :class="['ml']" :filters="usedFilters" />
        <transition name="fade">
          <div class="selected-studies" v-if="selectedStudy.length !== 0">
            <BaseButton
              class="share-selected-studies"
              type="cta"
              @click="shareMoreStudies = true"
            >
              {{ $t('base.share.shareSelectedStudies') }} ({{
                selectedStudy.length
              }})
            </BaseButton>
            <span @click="clearSelectedStudies" class="clear-selected-studies">
              <font-awesome-icon icon="times" :height="14"></font-awesome-icon>
            </span>
          </div>
        </transition>
      </template>
      <template #right>
        <Pager
          :all="filteredResourcesLength"
          v-model:page="page"
          v-model:per-page="perPage"
        />
      </template>
    </PatientHeader>
    <div class="patient-container">
      <transition name="fade">
        <EcgLoader v-if="loading" class="patient-loader">
          {{ $t('base.baseLoading') }}
        </EcgLoader>
      </transition>
      <transition name="fade">
        <div id="wrapper-expandable-fhirs" v-if="!loading">
          <ExpandablePanel
            :id="`expandable-fhir-${i}-${page}`"
            v-for="(pagedItem, i) in pagedResources"
            :name="pagedItem.patient.description"
            :key="pagedItem.patient.uuid"
            :class="[
              'mt',
              'expandable-panel-in-container',
              selectedStudy.find(it => it === pagedItem.patient.resource.id)
                ? 'expandable-panel-in-container--selected'
                : ''
            ]"
            :expanded="true"
            @focusin.prevent="setActiveFocused(i)"
          >
            <template #header-controls>
              <div
                class="resource-header resource-header--patient"
                :class="{ 'resource-header-admin': admin }"
              >
                <span
                  v-if="pagedItem.patient.resource.resourceType !== 'Patient'"
                >
                  {{ pagedItem.patient.date }}
                </span>
                <span>
                  {{
                    activeHeaders[i]
                      ? activeHeaders[i]
                      : pagedItem.patient.description
                  }},
                </span>
                <span>
                  {{
                    `${$t('user.pesel') +
                      ':' +
                      ' ' +
                      (pagedItem.patient.resource.identifier &&
                      pagedItem.patient.resource.identifier[0].value
                        ? pagedItem.patient.resource.identifier[0].value
                        : $t('user.lack'))}`
                  }},
                </span>
                <span>
                  {{
                    `${$t('user.age') +
                      ':' +
                      ' ' +
                      (getAge(pagedItem.patient.resource.birthDate) ||
                        $t('user.lack'))}`
                  }}
                </span>
              </div>
            </template>
            <div class="resource-content mt">
              <FhirComponentsRouter
                :resource="pagedItem.patient.resource"
                @get-active-header="getActiveHeader"
              >
                <!--                <JsonPreview :resource="pagedItem.patient.resource" />-->
              </FhirComponentsRouter>

              <p class="title-patient-view">
                {{ $t('patient.studyList') }} ({{
                  pagedItem.otherResources.length
                }})
              </p>
              <ExpandablePanel
                :id="`expandable-fhir-study-${i}-`"
                v-for="(itemRes, indexRes) in pagedItem.otherResources"
                :key="`indexRes-${i}-${indexRes}}`"
                :name="itemRes.description"
                :expanded="false"
                background-header
                color-header="#2b363f"
              >
                <template #header-controls>
                  <div
                    :class="[
                      'resource-header',
                      checkIfHeaderCanBeBolded(itemRes.resource.id)
                    ]"
                  >
                    <span>{{ itemRes.date }}</span>
                    <span class="desc-content">
                      {{ itemRes.description }}
                    </span>
                  </div>
                </template>
                <template #to-right>
                  <span
                    style="position: relative; margin-right: 10px;"
                    :title="`${$t('admin.sharedAmountInfo')}`"
                    v-if="
                      userActorConsentsByDocument(itemRes.resource).length !== 0
                    "
                  >
                    <span
                      style="margin-right: 5px;"
                      class="color-for-short-res count-shares"
                    >
                      {{ userActorConsentsByDocument(itemRes.resource).length }}
                    </span>
                    <font-awesome-icon
                      icon="users"
                      class="color-for-short-res"
                    />
                  </span>
                  <span
                    v-if="isLinkSharedForPeople(itemRes.resource)"
                    :title="`${$t('admin.linkGeneratedInfo')}`"
                    style="margin-right: 10px;"
                  >
                    <font-awesome-icon
                      icon="link"
                      class="color-for-short-res"
                    />
                  </span>
                  <span
                    :title="`${$t('admin.favouriteStudyInfo')}`"
                    v-if="
                      documentExistInFavouriteList(
                        itemRes.resource.id,
                        itemRes.resource.resourceType
                      )
                    "
                  >
                    <font-awesome-icon
                      icon="star"
                      class="color-for-short-res"
                    />
                  </span>
                </template>
                <div class="resource-content mt">
                  <FhirComponentsRouter
                    :resource="itemRes.resource"
                    @get-active-header="getActiveHeader"
                  />
                  <ResourcesPanel
                    :admin="admin"
                    :resource="itemRes.resource"
                    :block-share-button="selectedStudy.length !== 0"
                    :read-ids="readIds"
                    @toggled-favourite="
                      toggledFavourite($event, itemRes.resource)
                    "
                    @input="handleGetCheckbox"
                    @share-by-id-and-type="shareIdAndType = $event"
                    :share-button-id="`${shareDialogId}-${i}`"
                  />
                </div>
              </ExpandablePanel>
            </div>
          </ExpandablePanel>
          <EmptyInfo
            class="margin-empty-info"
            :delay="1000"
            v-if="pagedResources.length === 0 && !loading"
          />
        </div>
      </transition>
      <ShareModal
        @close-share-modal="handleCloseShareModal"
        :resource="handleOneResource"
        :more-resources="getMoreResourcesToShare"
        :share-more-studies="shareMoreStudies"
        :share-button-id="`${shareDialogId}-${activeFocused}`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  watch
} from 'vue';
import PatientHeader from '@/components/Patient/PatientHeader.vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import Pager from '@/components/Commons/Pager.vue';
import UsedFilters from '@/components/Filters/UsedFilters.vue';
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import ResourcesPanel from '@/components/Commons/ResourcesPanel/ResourcesPanel.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import ShareModal from '@/components/Commons/ResourcesPanel/Share/ShareModal/ShareModal.vue';
import PatientAndAdmin from '@/components/PatientAndAdmin/PatientAndAdmin';
import EmptyInfo from '@/components/Commons/EmptyInfo/EmptyInfo.vue';
import {
  FAVOURITES,
  Filter,
  FILTER,
  MODALITIES,
  SEX,
  TAGS,
  TEXT,
  FILTERING
} from '@/store/search/types';
import { useStore as useSearchStore } from '@/store/search';
import { debounce, reverse, sortBy } from 'lodash';
import { day, getDateFromResource, ts } from '@fhir/pixel-commons-js/src/index';
import { getDescriptionFromResource } from '@/components/Patient/PatientUtils';
import { generateUuid } from '@/utils/utils';
import { getAge } from '@/components/FhirComponents/utils/time.utils';
import { Filter as HeaderFilter } from '@/components/Filters/types';
import { CLEAR_SELECTED_STUDIES } from '@/store/studyShare/types';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import useResource from '@/composables/useResource';
import useConsent from '@/composables/useConsent';
import { System } from '@/components/Patient/PatientTags';
import useUser from '@/composables/useUser';
import i18n from '@/lang';

export default defineComponent({
  name: 'ForAdminContent',
  components: {
    EmptyInfo,
    ShareModal,
    BaseButton,
    ResourcesPanel,
    FhirComponentsRouter,
    UsedFilters,
    Pager,
    EcgLoader,
    ExpandablePanel,
    PatientHeader
  },
  setup() {
    const searchStore = useSearchStore();
    const studyShareStore = useStudyShareStore();
    const adminResources = { ...PatientAndAdmin() };
    const userActorConsentsByDocument = useConsent()
      .actorsForDocumentConsentsOnResource;
    const isLinkSharedForPeople = useConsent().isLinkSharedForPeople;
    const { checkIfTagExistInDocument, getLastFavouriteChange } = useResource();
    const refreshTime = Number(localStorage.getItem('refreshTime')) || 0;

    const filter = computed<Filter>(() => searchStore.getters[FILTER]);
    const modalities = computed(() => {
      const ids: string[] = (searchStore.getters[MODALITIES] || []).map(
        (m: any) => m.id
      );
      return window.modalities.filter(
        (f: any) => ids.find((id: string) => id === f.FBID) != null
      );
    });

    const userName = useUser().getUserName();

    const {
      addToFavourites,
      getFavouriteList,
      removeFromFavourites,
      documentExistInFavouriteList
    } = useResource();

    const favouriteList = getFavouriteList();

    const toggledFavourite = (isFavourite: boolean, resource: any) => {
      const type = resource.resourceType;
      const id = resource.id;
      isFavourite ? addToFavourites(id, type) : removeFromFavourites(id, type);
    };

    const clearSelectedStudies = () => {
      studyShareStore.commit(CLEAR_SELECTED_STUDIES);
    };

    const search = debounce(async (): Promise<any> => {
      console.log('search admin', filter.value);
      try {
        const tempResourcesLength: number =
          adminResources.resources.value.length;
        adminResources.activeHeaders.value = [];
        adminResources.resources.value = [];
        adminResources.page.value = 1;
        adminResources.loading.value = true;

        searchStore.commit(FILTERING, true);
        const data = await useResource().searchResources(
          filter.value,
          adminResources.selectedTenant.value
        );

        if (data) {
          const r: any[] = data.flatMap((m: any) => m?.data?.entry ?? []);
          const filteredResource: any[] = [];

          if (r.length !== tempResourcesLength) {
            adminResources.page.value = 1;
          }

          r.forEach((m: any) => {
            const resType = m.resource.resourceType;
            const resId = m.resource.id;
            const duplicate = filteredResource.find(
              resource =>
                resource.resource.resourceType === resType &&
                resource.resource.id === resId
            );
            if (!duplicate) filteredResource.push(m);
          });

          adminResources.resources.value = reverse(
            sortBy(
              filteredResource.map(m => {
                const date = getDateFromResource(m.resource);
                const desc = getDescriptionFromResource(m.resource) || '';
                return {
                  uuid: generateUuid(),
                  ts: Number(ts(date)),
                  date: day(date),
                  description: desc,
                  resource: m.resource,
                  doctor: m.resource.resourceType
                };
              }),
              'ts'
            )
          );
        }

        adminResources.pagedResources.value.forEach(res => {
          res.otherResources.forEach(otherRes => {
            const resource = otherRes.resource;
            const id = otherRes.resource.id;
            const type = otherRes.resource.resourceType;

            const tagExists = checkIfTagExistInDocument(resource, {
              system: System.patientPortalFavouriteSystem,
              code: userName
            });
            if (tagExists) addToFavourites(id, type);
          });
        });
      } catch (e) {
        // if (e.message !== CANCELLED_BY_USER) {
        //   useNotification().addNotification(
        //     NotificationType.ERROR,
        //     '',
        //     i18n.global.t('patient.results.error')
        //   );
        // }
        console.error(i18n.global.t('patient.results.error'), e);
      } finally {
        adminResources.loading.value = false;
        searchStore.commit(FILTERING, false);
      }
    }, 300);

    let refreshInterval: undefined | number;

    onMounted(async () => {
      await useConsent().loadListConsents();
    });

    onUnmounted(() => clearInterval(refreshInterval));

    onUpdated(() => {
      clearInterval(refreshInterval);
      if (refreshTime > 0) {
        refreshInterval = setInterval(() => {
          search();
        }, refreshTime * 1000);
      }
    });

    watch(
      filter,
      v => {
        search();
      },
      { deep: true, immediate: true }
    );

    const usedFilters = computed<HeaderFilter[]>(() => {
      const ret: HeaderFilter[] = [...adminResources.usedFilters.value];

      if (filter.value.favourites != null && filter.value.favourites === true) {
        const filterItem = {
          description: `${i18n.global.t('admin.filters.favouriteResearch')}`,
          remove: () => searchStore.commit(FAVOURITES, null)
        };
        ret.push(filterItem);
      }

      if (filter.value.text != null && filter.value.text.trim().length > 0) {
        const filterItem = {
          description: `${i18n.global.t('admin.filters.text')}: ${
            filter.value.text
          }`,
          remove: () => searchStore.commit(TEXT, null)
        };
        ret.push(filterItem);
      }

      if (filter.value.sex != null && filter.value.sex !== 0) {
        const filterItem = {
          description: `${i18n.global.t('admin.filters.sex')}: ${i18n.global.t(
            `admin.filters.${filter.value.sex === 1 ? 'woman' : 'man'}`
          )}`,
          remove: () => searchStore.commit(SEX, 0)
        };
        ret.push(filterItem);
      }

      if (filter.value.modality != null && filter.value.modality.length > 0) {
        const filterItem = {
          description: `${i18n.global.t(
            'admin.filters.criterion'
          )}: ${filter.value.modality.map(m => m.label).join(', ')}`,
          remove: () => searchStore.commit(MODALITIES, [])
        };
        ret.push(filterItem);
      }

      if (filter.value.tags != null && filter.value.tags.length > 0) {
        const filterItem = {
          description: `${i18n.global.t(
            'admin.filters.tags'
          )}: ${filter.value.tags.map(m => m.name).join(', ')}`,
          remove: () => searchStore.commit(TAGS, null)
        };
        ret.push(filterItem);
      }

      return ret;
    });

    return {
      ...adminResources,
      search,
      usedFilters,
      userActorConsentsByDocument,
      checkIfTagExistInDocument,
      System,
      toggledFavourite,
      userName,
      getAge,
      favouriteList,
      clearSelectedStudies,
      getLastFavouriteChange,
      documentExistInFavouriteList,
      isLinkSharedForPeople
    };
  }
});
</script>

<style scoped lang="scss">
.patient-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0px;
  /*background-color: var(--light);*/
  border-radius: var(--main-radius);
  position: relative;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.patient-loader {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.resource-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 0px;
  font-weight: 300;
  color: var(--font-color);

  &--patient {
    font-size: 1.225rem;
  }
}
.resource-header-admin {
  grid-template-columns: auto auto auto 1fr;
  span {
    padding-right: 0 !important;
    border: none !important;
  }
}

.resource-header > * {
  padding-right: 5px;
}

.resource-header > *:not(:first-child) {
  border-left: solid 1px var(--font-color);
  padding: 0px 5px;
}

.plain {
  white-space: pre-wrap;
  word-break: break-word;
  overflow: auto;
}

.icon-pat-header {
  cursor: pointer;
  background: transparent;
  color: var(--logo);
  font-size: 0.875rem;
  padding: var(--app-base-space);
  transition: color 0.15s ease, transform 0.15s ease;
  border: 2px solid transparent;
  &:hover {
    color: var(--accent);
  }
  &:active {
    color: var(--accent);
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
    border: 2px solid transparent;
  }
  &:focus-visible {
    border: 2px solid var(--accent);
  }
}

.patient-footer {
  display: grid;
  place-items: end;
}

.resource-content {
  display: grid;
  grid-template-rows: 1fr auto;
}

.resource-footer {
  border-top: solid 1px var(--medium-gray);
  padding: 3px;
  display: flex;
  justify-content: flex-end;
}

.resource-footer > * {
  margin-left: 5px;
}

.expandable-panel-in-container {
  background-color: var(--light);
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.15s ease;
  &--selected {
    border-bottom: 3px solid var(--accent);
  }

  .resource-content {
    margin-top: 2px !important;
    margin-bottom: 4px !important;
  }
}
.patient-header-item {
  transition: transform 0.15s ease;
}
.hide-options-when-study-selected {
  transform: translateY(-50px);
}
.selected-studies {
  position: relative;
  .share-selected-studies {
    margin-left: var(--app-base-space);
    font-size: 0.875rem;
    padding: 4px calc(var(--app-base-space) * 3.3) 4px var(--app-base-space);
    &:active {
      transform: none !important;
    }
  }
  .clear-selected-studies {
    border: none;
    position: absolute;
    top: calc(50% + 1px);
    right: 4.5px;
    transform: translateY(-50%);
    border-radius: 50%;
    border: none;
    padding: 2px 4px;
    background-color: var(--accent);
    cursor: pointer;
    svg {
      color: var(--primary);
      padding-top: 1px;
      transform: scale(0.94);
      transition: 0.15s ease;
    }
    &:hover {
      svg {
        transform: scale(1.14);
      }
    }
  }
}

.title-patient-view {
  margin: var(--app-margin) 0 0 var(--app-base-space);
  font-size: 1.225rem;
  font-weight: 300;
  color: var(--main-color);
  cursor: default;
}
.font-bold {
  font-weight: 700;
}
.margin-empty-info {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--app-margin);
}

.color-for-short-res {
  color: var(--dark-gray);
}
.count-shares {
  margin-right: 5px;
  font-weight: 600;
  font-size: 0.875rem;
}
.desc-content {
  display: inline-block;
  max-width: max-content;
}
</style>
