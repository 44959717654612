import { copy, sleep } from '@/utils/utils';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import useConsent from '@/composables/useConsent';

export default (props: any) => {
  const { t } = useI18n();

  const link = ref<string | null>(null);

  const linkNode = ref<Node>();

  const savingLink = ref<boolean>(false);

  const removingLink = ref<boolean>(false);

  const copyLink = () =>
    copy(linkNode.value, () =>
      useNotification().addNotification(
        NotificationType.INFO,
        '',
        t('base.copied')
      )
    );

  const getLink = (id: string) =>
    `${window.location.origin}/portal-pacjenta/shared/${id}`;

  const generateLink = async (moreResources?: boolean) => {
    savingLink.value = true;
    const resource = moreResources ? props.moreResources[0] : props.resource;
    try {
      const response = await useConsent().addConsentByLink(resource);
      if (response) {
        link.value = getLink(response.id);
        savingLink.value = false;
      }
    } finally {
      savingLink.value = false;
    }
  };

  const confirm = inject<any>('confirm');

  const deleteLink = async () => {
    try {
      await confirm.value.confirm(
        t('base.share.caution'),
        t('base.share.confirmDeleteLink')
      );
      try {
        removingLink.value = true;
        await useConsent().removeConsentByLink(link.value);

        link.value = null;

        useNotification().addNotification(
          NotificationType.INFO,
          '',
          t('base.share.linkRemoved')
        );
      } catch (e) {
        useNotification().addNotification(
          NotificationType.ERROR,
          '',
          t('base.share.errorRemovingLink')
        );
        console.error(t('base.share.errorRemovingLink'), e);
      } finally {
        removingLink.value = false;
      }
    } catch (e) {
      console.log('');
    }
  };

  return {
    link,
    linkNode,
    copyLink,
    generateLink,
    deleteLink,
    savingLink,
    removingLink,
    getLink
  };
};
