<template>
  <ul class="resources-panel">
    <li
      class="resources-panel__item resources-panel__item--first"
      v-if="!isConsentSharedOnlyForMe && !admin"
    >
      <div class="wrapper-flex">
        <BaseButton
          type="cta"
          :class="[
            'set-study-read',
            checkIfResourceDisplayed ? 'set-study-read--read' : ''
          ]"
          :is-disabled="checkIfResourceDisplayed"
          @click="handleSetRead"
        >
          {{ $t('base.markAsDisplayed') }}
        </BaseButton>
        <p
          :class="[
            'read-text',
            checkIfResourceDisplayed ? 'read-text--show' : ''
          ]"
        >
          <font-awesome-icon icon="check" :height="10" />
          {{ $t('base.showing') }}
        </p>
      </div>
    </li>
    <li class="resources-panel__item" v-if="!isConsentSharedOnlyForMe">
      <BaseCheckbox
        :title="$t('base.checkboxAriaLabel')"
        ref="input"
        :checkbox-id="resource.id"
        :checkbox-label="''"
        :checkbox-value="isChecked"
        :aria-label="$t('base.checkboxAriaLabel')"
        @input="handleInput"
      />
    </li>
    <li
      v-if="
        resource.resourceType === 'ImagingStudy' && !isConsentSharedOnlyForMe
      "
      class="resources-panel__item"
    >
      <RecordOnCd :resource="resource" />
    </li>
    <li class="resources-panel__item">
      <Download :resource="resource" />
    </li>
    <li
      class="resources-panel__item"
      v-if="isAdmin && !isConsentSharedOnlyForMe"
    >
      <Favourite @toggled-favourite="toggledFavourite" :resource="resource" />
    </li>
    <li class="resources-panel__item" v-if="!isConsentSharedOnlyForMe">
      <Share
        :resource="resource"
        @share-by-id-and-type="$emit('share-by-id-and-type', $event)"
        :block-share-button="blockShareButton"
        :share-button-id="shareButtonId"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import Share from '@/components/Commons/ResourcesPanel/Share/Share.vue';
import Download from '@/components/Commons/ResourcesPanel/Download/Download.vue';
import RecordOnCd from '@/components/Commons/ResourcesPanel/RecordOnCd/RecordOnCd.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { computed, onUnmounted, ref, watch } from 'vue';
import {
  SELECTED_STUDY,
  CLEAR_SELECTED_STUDIES
} from '@/store/studyShare/types';
import BaseCheckbox from '@/components/Base/BaseCheckbox.vue';
import Favourite from '@/components/Commons/ResourcesPanel/Favourite/Favourite.vue';
import useUser from '@/composables/useUser';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import useResource from '@/composables/useResource';

interface EmitInputOrEnterType {
  id: string;
  isChecked: boolean;
  value: boolean;
  ev: InputEvent | KeyboardEvent;
}

export interface InputInitValue {
  id: string;
}

export default {
  name: 'ResourcesPanel',
  emits: [
    'input',
    'share-by-id-and-type',
    'set-read-by-id',
    'toggled-favourite'
  ],
  components: {
    Favourite,
    BaseCheckbox,
    BaseButton,
    RecordOnCd,
    Download,
    Share
  },
  props: {
    resource: { required: true, type: Object },
    blockShareButton: { required: false, default: false, type: Boolean },
    readIds: { required: false, type: Array },
    shareButtonId: { required: false, type: String, default: '0' },
    /**
     *  admin - boolean
     *  if admin true - set some properties visible like 'share'
     */
    admin: { required: false, type: Boolean, default: false }
  },
  setup(
    props: any,
    context: {
      emit: (arg0: string, arg1?: InputInitValue | boolean) => void;
    }
  ) {
    const studyRead = ref(false);
    const studyShareStore = useStudyShareStore();
    const input = ref<HTMLInputElement>();
    const isChecked = ref(false);

    const handleSetRead = () => {
      context.emit('set-read-by-id', props.resource.id);
    };

    onUnmounted(() => {
      studyShareStore.commit(CLEAR_SELECTED_STUDIES);
      useResource().clearFavouriteList();
    });

    const checkIfResourceDisplayed = computed(() =>
      props.readIds.includes(props.resource.id)
    );

    const toggledFavourite = (isFavourite: boolean) => {
      context.emit('toggled-favourite', isFavourite);
    };

    watch(
      () => studyShareStore.getters[SELECTED_STUDY],
      val => {
        if (val.length === 0) {
          isChecked.value = false;
        }
      }
    );

    const handleInput = (ev: EmitInputOrEnterType) => {
      isChecked.value = !isChecked.value;

      console.log(ev);

      context.emit('input', {
        id: ev.id
      });
    };

    const isAdmin = computed(() => useUser().isAdmin());
    const isConsentSharedOnlyForMe = computed(
      () => !!props.resource?.isConsent
    );

    return {
      handleSetRead,
      studyRead,
      checkIfResourceDisplayed,
      handleInput,
      isChecked,
      input,
      toggledFavourite,
      isAdmin,
      isConsentSharedOnlyForMe
    };
  }
};
</script>

<style lang="scss" scoped>
.resources-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  list-style-type: none;
  position: relative;
  overflow-x: hidden;
}
.resources-panel__item {
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
}

.resources-panel__item--first {
  margin-left: 0;
  margin-right: auto;
  position: relative;
}

.set-study-read {
  font-size: 0.775rem;
  padding: 5px;
  &--read {
    transform: translateX(-300px);
  }
}

.read-text {
  position: absolute;
  bottom: 0;
  left: -200px;
  color: var(--dark-gray);
  font-size: 0.8rem;
  transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);

  &--show {
    transform: translateX(200px);
  }
}
.wrapper-flex {
  display: flex;
  align-items: center;
}
</style>
