<template>
  <template v-if="!isUnknownUser">
    <ForAdminContent v-if="isAdmin" />
    <ForPatientContent v-if="!isAdmin" />
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from 'vue';
import ForAdminContent from '@/views/Patient/ForAdminContent.vue';
import ForPatientContent from '@/views/Patient/ForPatientContent.vue';
import useUser from '@/composables/useUser';
import router from '@/router';

export default defineComponent({
  name: 'Patient',
  components: {
    ForAdminContent,
    ForPatientContent
  },
  setup() {
    const isAdmin = computed(() => useUser().isAdmin());
    const isUnknownUser = computed(() => useUser().isUnknownUser());

    onMounted(() => {
      if (isUnknownUser.value) router.push({ name: 'Forbidden' });
    });

    return {
      isAdmin,
      isUnknownUser
    };
  }
});
</script>
