<template>
  <div>
    <PatientHeader @reload="search" :is-smaller="isSmaller" :id="'header-app'">
      <template #default>
        <button
          :aria-label="$t('patient.results.reload')"
          :class="['icon-pat-header', 'flex-layout']"
          @click="search"
        >
          <font-awesome-icon icon="redo" :height="12"></font-awesome-icon>
          <span class="ml">{{ $t('patient.results.reload') }}</span>
        </button>
        <UsedFilters :class="['ml']" :filters="usedFilters" />
        <transition name="fade">
          <div class="selected-studies" v-if="selectedStudy.length !== 0">
            <BaseButton
              class="share-selected-studies"
              type="cta"
              @click="shareMoreStudies = true"
            >
              {{ $t('base.share.shareSelectedStudies') }} ({{
                selectedStudy.length
              }})
            </BaseButton>
            <span @click="clearSelectedStudies" class="clear-selected-studies">
              <font-awesome-icon icon="times" :height="14"></font-awesome-icon>
            </span>
          </div>
        </transition>
      </template>
      <template #right>
        <Pager
          :all="filteredResourcesLength"
          v-model:page="page"
          v-model:per-page="perPage"
        />
      </template>
    </PatientHeader>
    <div class="patient-container">
      <transition name="fade">
        <EcgLoader v-if="loading" class="patient-loader">
          {{ $t('base.baseLoading') }}
        </EcgLoader>
      </transition>
      <transition name="fade">
        <div id="wrapper-expandable-fhirs" v-if="!loading">
          <div class="resource-content mt">
            <p
              v-if="pagedResourcesOnlyDocuments.length !== 0"
              class="title-patient-view"
            >
              {{ $t('patient.studyListForPage') }} ({{
                pagedResourcesOnlyDocuments.length
              }})
            </p>
            <ExpandablePanel
              class="patient-one-study"
              :id="`expandable-fhir-study-${i}-`"
              v-for="(pagedItem, i) in pagedResourcesOnlyDocuments"
              :key="`indexRes-${i}-${i}`"
              :name="pagedItem.description"
              :expanded="true"
            >
              <template #header-controls>
                <div class="resource-header font-bold">
                  <!--                  <div-->
                  <!--                  :class="[-->
                  <!--                    'resource-header',-->
                  <!--                    checkIfHeaderCanBeBolded(pagedItem.resource.id)-->
                  <!--                  ]"-->
                  <!--                >-->
                  <span>{{ pagedItem.date }}</span>
                  <span class="desc-item">
                    {{ pagedItem.description }}
                    <span class="desc-items">
                      <SharedBadge
                        v-if="
                          pagedItem.resource && pagedItem.resource.isConsent
                        "
                      >
                        {{
                          pagedItem.consentor
                            ? `${$t('base.sharedForMeBy')} ${
                                pagedItem.consentor
                              }`
                            : $t('base.sharedForMe')
                        }}
                      </SharedBadge>
                      <SharedBadge
                        class="ml"
                        :class="{
                          'shared-patient': pagedItem.resource.isConsent
                        }"
                        :type="pagedItem.resource.isConsent ? 'shared' : 'user'"
                        :ico="
                          pagedItem.resource.isConsent ? 'share-alt' : 'user'
                        "
                        v-if="pagedItem.patient"
                      >
                        {{ pagedItem.patient }}
                      </SharedBadge>
                    </span>
                  </span>
                </div>
              </template>
              <div class="resource-content mt">
                <FhirComponentsRouter
                  :resource="pagedItem.resource"
                  @get-active-header="getActiveHeader"
                />
                <ResourcesPanel
                  :admin="admin"
                  :resource="pagedItem.resource"
                  :block-share-button="selectedStudy.length !== 0"
                  :read-ids="readIds"
                  @input="handleGetCheckbox"
                  @share-by-id-and-type="shareIdAndType = $event"
                  @set-read-by-id="
                    addDisplayedResourceTag(pagedItem.resource, readIds)
                  "
                  :share-button-id="`${shareDialogId}-${i}`"
                />
              </div>
            </ExpandablePanel>
          </div>
          <EmptyInfo
            class="margin-empty-info"
            :delay="1000"
            v-if="pagedResourcesOnlyDocuments.length === 0 && !loading"
          />
        </div>
      </transition>
      <ShareModal
        @close-share-modal="handleCloseShareModal"
        :resource="handleOneResource"
        :more-resources="getMoreResourcesToShare"
        :share-more-studies="shareMoreStudies"
        :share-button-id="`${shareDialogId}-${activeFocused}`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, onMounted, onUnmounted, onUpdated } from 'vue';
import PatientHeader from '@/components/Patient/PatientHeader.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import Pager from '@/components/Commons/Pager.vue';
import UsedFilters from '@/components/Filters/UsedFilters.vue';
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import ResourcesPanel from '@/components/Commons/ResourcesPanel/ResourcesPanel.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import ShareModal from '@/components/Commons/ResourcesPanel/Share/ShareModal/ShareModal.vue';
import { CLEAR_SELECTED_STUDIES } from '@/store/studyShare/types';
import PatientAndAdmin from '@/components/PatientAndAdmin/PatientAndAdmin';
import EmptyInfo from '@/components/Commons/EmptyInfo/EmptyInfo.vue';
import PatientTages from '@/components/Patient/PatientTags';
import SharedBadge from '@/components/Commons/SharedBadge.vue';
import useConsent from '@/composables/useConsent';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';

export default defineComponent({
  name: 'ForPatientContent',
  components: {
    SharedBadge,
    EmptyInfo,
    ShareModal,
    BaseButton,
    ResourcesPanel,
    FhirComponentsRouter,
    UsedFilters,
    Pager,
    ExpandablePanel,
    EcgLoader,
    PatientHeader
  },
  setup() {
    const {
      search,
      isSmaller,
      usedFilters,
      selectedStudy,
      shareMoreStudies,
      filteredResourcesLength,
      page,
      perPage,
      loading,
      pagedResourcesOnlyDocuments,
      checkIfHeaderCanBeBolded,
      getActiveHeader,
      admin,
      readIds,
      handleGetCheckbox,
      shareDialogId,
      handleCloseShareModal,
      handleOneResource,
      getMoreResourcesToShare,
      activeFocused,
      shareIdAndType,
      dateFilter,
      patientFilter,
      sharedFilter,
      getterSearchText
    } = PatientAndAdmin();
    const studyShareStore = useStudyShareStore();

    const refreshTime = Number(localStorage.getItem('refreshTime')) || 0;
    let refreshInterval: undefined | number;

    const clearSelectedStudies = () => {
      studyShareStore.commit(CLEAR_SELECTED_STUDIES);
    };

    onMounted(async () => {
      await useConsent().loadListConsents();
    });

    onUnmounted(() => clearInterval(refreshInterval));

    onUpdated(() => {
      clearInterval(refreshInterval);
      if (refreshTime > 0) {
        refreshInterval = setInterval(() => {
          search();
        }, refreshTime * 1000);
      }
    });

    const { addDisplayedResourceTag } = PatientTages();

    watch(
      sharedFilter,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );
    watch(
      dateFilter,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );
    watch(
      patientFilter,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );
    watch(
      getterSearchText,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );

    return {
      search,
      isSmaller,
      usedFilters,
      selectedStudy,
      shareMoreStudies,
      filteredResourcesLength,
      page,
      sharedFilter,
      perPage,
      loading,
      pagedResourcesOnlyDocuments,
      checkIfHeaderCanBeBolded,
      getActiveHeader,
      admin,
      readIds,
      handleGetCheckbox,
      shareDialogId,
      handleCloseShareModal,
      handleOneResource,
      clearSelectedStudies,
      getMoreResourcesToShare,
      activeFocused,
      shareIdAndType,
      addDisplayedResourceTag
    };
  }
});
</script>

<style scoped lang="scss">
.mt {
  margin-top: 0;
}

.patient-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0px;
  /*background-color: var(--light);*/
  border-radius: var(--main-radius);
  position: relative;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.patient-loader {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.resource-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 0px;
  font-weight: 300;

  &--patient {
    font-size: 1.225rem;
  }
}

.resource-header-admin {
  grid-template-columns: auto 1fr auto;
}

.resource-header > * {
  padding-right: 5px;
}

.resource-header > *:not(:first-child) {
  border-left: solid 1px black;
  padding: 0px 5px;
}

.plain {
  white-space: pre-wrap;
  word-break: break-word;
  overflow: auto;
}

.icon-pat-header {
  cursor: pointer;
  background: transparent;
  color: var(--logo);
  font-size: 0.875rem;
  padding: var(--app-base-space);
  transition: color 0.15s ease, transform 0.15s ease;
  border: 2px solid transparent;

  &:hover {
    color: var(--accent);
  }

  &:active {
    color: var(--accent);
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
    border: 2px solid transparent;
  }

  &:focus-visible {
    border: 2px solid var(--accent);
  }
}

.patient-footer {
  display: grid;
  place-items: end;
}

.resource-content {
  display: grid;
  grid-template-rows: 1fr auto;
}

.resource-footer {
  border-top: solid 1px var(--medium-gray);
  padding: 3px;
  display: flex;
  justify-content: flex-end;
}

.resource-footer > * {
  margin-left: 5px;
}

.expandable-panel-in-container {
  background-color: var(--light);
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.15s ease;

  &--selected {
    border-bottom: 3px solid var(--accent);
  }

  .resource-content {
    margin-top: 2px !important;
    margin-bottom: 4px !important;
  }
}

.patient-header-item {
  transition: transform 0.15s ease;
}

.hide-options-when-study-selected {
  transform: translateY(-50px);
}

.selected-studies {
  position: relative;

  .share-selected-studies {
    margin-left: var(--app-base-space);
    font-size: 0.875rem;
    padding: 4px calc(var(--app-base-space) * 3.3) 4px var(--app-base-space);
  }

  .clear-selected-studies {
    border: none;
    position: absolute;
    top: calc(50% + 1px);
    right: 8px;
    transform: translateY(-50%);
    border-radius: 50%;
    border: none;
    padding: 2px 4px;
    background-color: var(--accent);
    cursor: pointer;

    svg {
      color: var(--primary);
      padding-top: 1px;
      transform: scale(0.94);
      transition: 0.15s ease;
    }

    &:hover {
      svg {
        transform: scale(1.14);
      }
    }
  }
}

.title-patient-view {
  margin: var(--app-margin) 0 0 var(--app-base-space);
  font-size: 1.225rem;
  font-weight: 500;
  color: var(--main-color);
  margin-bottom: var(--app-base-space);
  margin-top: 0;
}

.font-bold {
  font-weight: 700;
}

.margin-empty-info {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--app-margin);
}

.patient-one-study {
  color: var(--font-color);
  padding: var(--app-base-space);
  background: var(--light);
  border-radius: var(--main-radius);
  margin-bottom: var(--app-base-space);
  :is(button.expandable-panel-header) {
    padding: 3px 0;
    &:hover {
      background-color: #eaeaea;
    }
    &:focus {
      background-color: #eaeaea;
    }
  }
}

.desc-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desc-items {
  display: flex;
  align-items: center;
}
</style>
