<template>
  <div class="base-input-group">
    <div class="base-input" :class="{ 'base-input__error': isInvalid }">
      <label :class="{ visuallyhidden: isLabelHidden }" :for="inputId">
        {{ inputLabel }}
      </label>
      <input
        :id="inputId"
        :name="inputId"
        :aria-label="ariaLabel"
        :placeholder="placeholderInfo"
        @input="$emit('input', $event.target.value)"
        @keyup="$emit('keyup', $event.target.value)"
        @click="$emit('click', $event)"
        :value="inputData"
        :disabled="isDisabled"
        :aria-describedby="ariaDescribedby"
        :aria-required="ariaRequired"
        :aria-invalid="ariaInvalid.toString()"
        class="base-input__field"
        :type="inputType"
        :inputmode="inputMode"
        :maxlength="maxLength"
      />
      <slot name="times"></slot>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'BaseInput',
  emits: ['input', 'keyup', 'click'],
  props: {
    inputId: {
      type: String,
      required: true,
      default: 'base-input'
    },
    isLabelHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    inputLabel: {
      type: String,
      required: false,
      default: 'Default label'
    },
    inputType: {
      type: String,
      required: false,
      default: 'text'
    },
    placeholderInfo: {
      type: String,
      required: false,
      default: 'Default placeholder'
    },
    inputData: {
      type: [String, Number],
      required: false,
      default: ''
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isInvalid: {
      type: Boolean,
      required: false,
      default: false
    },
    inputMode: {
      type: String,
      required: false,
      default: 'text',
      validator: propValue =>
        ['text', 'numeric', 'email', 'url', 'tel'].includes(propValue)
    },
    ariaLabel: { required: false, type: String, default: '' },
    ariaDescribedby: { required: false, type: String, default: '' },
    ariaRequired: { required: false, type: String, default: '' },
    ariaInvalid: { required: false, type: String, default: '' }
  }
};
</script>

<style scoped lang="scss">
.base-input-group {
  display: flex;
  flex-direction: column;
}
.base-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  & label {
    font-size: var(--base-font-size__md);
    margin-bottom: 4px;
  }
  &__field {
    //border-radius: var(--main-radius);
    color: var(--font-color);
    padding: var(--base-padding);
    font-size: var(--input-font-size);
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--primary);
    &:disabled {
      border-color: var(--primary-lighten);
      color: var(--font-color-lighten);
    }
    &:focus {
      outline: none;
      border-color: var(--main-color);
    }
  }
  &__error input {
    border-color: var(--danger);
  }
  &__error label {
    color: var(--red);
  }
}
</style>
