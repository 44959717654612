
import {
  computed,
  defineComponent,
  watch,
  ref,
  onMounted,
  nextTick
} from 'vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import { generateUuid } from '@/utils/utils';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';

export default defineComponent({
  name: 'Pager',
  components: { RadioButton, RadioGroup, FocusLoop },
  props: {
    all: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true,
      default: 1
    },
    perPage: {
      type: Number,
      required: true,
      default: 10
    },
    negative: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:page', 'update:perPage'],
  setup(props, { emit }) {
    const pages = computed(() => Math.ceil(props.all / props.perPage));
    const nextPageAvailable = computed(() => props.page < pages.value);
    const prevPageAvailable = computed(() => props.page > 1);

    const nextPage = () => {
      if (nextPageAvailable.value) {
        emit('update:page', props.page + 1);
      }
    };

    const prevPage = () => {
      if (prevPageAvailable.value) {
        emit('update:page', props.page - 1);
      }
    };

    const itemsFrom = computed(() => (props.page - 1) * props.perPage);
    const itemsTo = computed(() =>
      itemsFrom.value + props.perPage < props.all
        ? itemsFrom.value + props.perPage
        : props.all
    );

    const showSettings = ref(false);
    const setPages = (perPage: number) => {
      const pages = Math.ceil(props.all / perPage);
      if (props.page > pages) {
        emit('update:page', pages);
      }
      emit('update:perPage', perPage);
      showSettings.value = false;
    };

    const pagerButton = ref<HTMLElement>();
    const pagerSettings = ref<HTMLElement>();

    watch(showSettings, v => {
      if (v) {
        nextTick(() => pagerSettings.value?.focus());
      } else {
        nextTick(() => pagerButton.value?.focus());
      }
    });

    const perPages = computed(() => [
      { id: 10, label: '10' },
      { id: 20, label: '20' },
      { id: 50, label: '50' },
      { id: 100, label: '100' }
    ]);

    const uuid = computed(() => generateUuid());

    const gotoPage = ref(1);
    watch(gotoPage, v => {
      if (gotoPage.value > pages.value) {
        gotoPage.value = pages.value;
      }
      if (gotoPage.value <= 0) {
        gotoPage.value = 1;
      }
    });
    watch(pages, v => {
      if (gotoPage.value > v) {
        gotoPage.value = v;
      }
    });

    const setPage = (page: number) => {
      emit('update:page', Number(page));
      showSettings.value = false;
    };

    return {
      pages,
      nextPageAvailable,
      prevPageAvailable,
      nextPage,
      prevPage,
      itemsFrom,
      itemsTo,
      setPages,
      showSettings,
      perPages,
      uuid,
      pagerButton,
      pagerSettings,
      gotoPage,
      setPage
    };
  }
});
