<template>
  <BaseModal
    v-model:value="modal"
    show-close
    :title="`${$t('base.share.name')} `"
    :last-element-clicked="shareButtonId"
  >
    <template #header>
      <span class="share-header">{{ $t('base.share.name') }}</span>
    </template>

    <div class="share-content">
      <!--      <pre>{{ moreResources }}</pre>-->
      <!--      <pre>{{ consentsListChips }}</pre>-->
      <!--      <pre>{{ linkConsent }}</pre>-->
      <div class="share-area">
        <div>{{ $t('base.share.shareTo') }}</div>
        <EcgLoader short-mode v-if="loading" class="ecg-share-loader">
          {{ $t('base.share.loading') }}
        </EcgLoader>
        <div class="consents mt">
          <template v-for="(c, i) in consentsListChips" :key="i">
            <BaseChip
              :closable="!deletingEmailOrPhone"
              v-for="(shared, idx) in c.sharedFor"
              :key="idx"
              @close="deleteByEmailOrPhone(shared)"
              class="chip-accent"
              :aria-label-new="`${$t('base.removeShare')} ${shared.whom}`"
            >
              {{ shared.whom }}
            </BaseChip>
          </template>
        </div>
        <EcgLoader
          short-mode
          class="ecg-share-loader"
          v-if="savingEmailOrPhone"
        >
          {{ $t('base.share.sharing') }}
        </EcgLoader>
        <EcgLoader
          short-mode
          class="ecg-share-loader"
          v-else-if="deletingEmailOrPhone"
        >
          {{ $t('base.share.removingShare') }}
        </EcgLoader>
        <div
          :class="[
            'share-person mt',
            savingEmailOrPhone || deletingEmailOrPhone
              ? 'share-person--disabled'
              : ''
          ]"
        >
          <BaseInput
            :aria-label="$t('base.share.enterEmailOrPhone')"
            :input-data="emailOrPhone"
            @input="emailOrPhone = $event"
            class="share-input"
            :is-label-hidden="true"
            :is-invalid="!inputOk"
            :placeholder-info="$t('base.share.enterEmailOrPhone')"
            :input-id="generateUuid()"
            :is-disabled="savingEmailOrPhone || deletingEmailOrPhone"
          />
          <BaseButton
            :is-disabled="
              !okToShare || savingEmailOrPhone || deletingEmailOrPhone
            "
            @click="shareByEmailOrPhone"
          >
            {{ $t('base.share.share') }}
          </BaseButton>

          <div class="wrapper-alert">
            <div role="alert" v-if="!inputOk" class="share-person-alert">
              * {{ $t('base.share.enterValidEmailOrPhone') }}
            </div>
            <div
              role="alert"
              v-if="inputOk && consentExistInResource"
              class="share-person-alert"
            >
              * {{ $t('base.share.consentAlreadyExistForThisActor') }}
            </div>
          </div>
        </div>
      </div>
      <div class="share-area mt">
        <div>{{ $t('base.share.shareByLink') }}</div>
        <EcgLoader short-mode class="ecg-share-loader" v-if="loading">
          {{ $t('base.share.loading') }}
        </EcgLoader>
        <EcgLoader short-mode class="ecg-share-loader" v-else-if="savingLink">
          {{ $t('base.share.generatingLink') }}
        </EcgLoader>
        <EcgLoader short-mode class="ecg-share-loader" v-else-if="removingLink">
          {{ $t('base.share.removingLink') }}
        </EcgLoader>
        <div v-else class="share-person mt">
          <a class="link" target="_blank" ref="linkNode" :href="link">
            {{ link }}
          </a>
          <BaseButtonGroup>
            <BaseButtonGroup v-if="link && !isMoreResources">
              <BaseButton @click="copyLink">
                {{ $t('base.share.copy') }}
                <font-awesome-icon icon="link" aria-hidden="true" />
              </BaseButton>
              <BaseButton @click="deleteLink">
                {{ $t('base.share.deleteLink') }}
              </BaseButton>
            </BaseButtonGroup>

            <BaseButton
              v-else
              :isDisabled="isMoreResources"
              @click="handleGenerateLinkButton"
            >
              {{ $t('base.share.generateLink') }}
            </BaseButton>
          </BaseButtonGroup>
          <p v-if="isMoreResources" class="generate-link-info">
            {{ $t('base.share.generateLinkForOneResourceInfo') }}
          </p>
        </div>
      </div>
    </div>
    <template #footer>
      <BaseButtonGroup>
        <BaseButton type="cancel" @click="handleCloseShareModal">
          {{ $t('base.close') }}
        </BaseButton>
      </BaseButtonGroup>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseButtonGroup from '@/components/Base/BaseButtonGroup.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import BaseLoader from '@/components/Base/BaseLoader.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseChip from '@/components/Base/BaseChip.vue';
import { generateUuid } from '@/utils/utils';
import shareByLinkSetup from '@/components/Commons/ResourcesPanel/Share/ShareByLink';
import shareByEmailOrPhoneSetup from '@/components/Commons/ResourcesPanel/Share/ShareByEmailOrPhone';
import shareResourcesSetup from '@/components/Commons/ResourcesPanel/Share/ShareResources';
import isEmpty from 'lodash/isEmpty';
import { CLEAR_SELECTED_STUDIES } from '@/store/studyShare/types';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import useConsent from '@/composables/useConsent';
import { replaceAll } from '@fhir/pixel-commons-js';

export default defineComponent({
  name: 'ShareModal',
  emits: ['close-share-modal'],
  components: {
    BaseChip,
    BaseInput,
    BaseLoader,
    EcgLoader,
    BaseButtonGroup,
    BaseButton,
    BaseModal
  },
  props: {
    resource: {
      type: Object as PropType<any>,
      required: true
    },
    moreResources: {
      type: Array as PropType<object[]>,
      required: false
    },
    shareMoreStudies: {
      type: Boolean,
      required: false
    },
    shareButtonId: {
      type: String,
      required: false,
      default: '0'
    }
  },
  setup(props, context) {
    const modal = ref(false);
    const studyShareStore = useStudyShareStore();

    const isMoreResources = ref<boolean>(false);
    const { loading, resources } = shareResourcesSetup(props);

    const consentsListChips: any = ref([]);
    const consentList = computed(() => useConsent().getListConsents());

    const linkConsent = computed(() =>
      consentsListChips.value.filter((f: any) => f.sharedFor.length === 0)
    );

    const {
      link,
      linkNode,
      copyLink,
      generateLink,
      deleteLink,
      savingLink,
      removingLink,
      getLink
    } = shareByLinkSetup(props);

    const handleGenerateLinkButton = () => {
      const moreResources = !!props.moreResources?.length;
      generateLink(moreResources);
    };

    watch(
      consentList,
      v => {
        consentsListChips.value = useConsent().findConsentsRelatedToDocument(
          props.resource
        );
      },
      { deep: true, immediate: true }
    );

    watch(
      modal,
      v => {
        if (v) {
          let resource;
          const resourcesLength = props.moreResources?.length || 0;
          if (resourcesLength === 1 && props.moreResources != undefined) {
            resource = props.moreResources[0];
          } else if (resourcesLength > 1 && props.moreResources != undefined) {
            resource = [];
          } else {
            resource = props.resource;
          }
          consentsListChips.value = useConsent().findConsentsRelatedToDocument(
            resource
          );
        }
      },
      { deep: true, immediate: true }
    );

    const idRef = ref(generateUuid());

    watch(props, value => {
      const resourcesLength = value.moreResources?.length || 0;
      resourcesLength > 1
        ? (isMoreResources.value = true)
        : (isMoreResources.value = false);
    });

    const updateLink = (value: any[]) => {
      if (value && value.length > 0) {
        const lc = value[0];
        if (lc && lc.documentId) {
          const newLink = getLink(replaceAll(lc.documentId, 'Consent/', ''));
          link.value = newLink;
        }
      }
    };

    watch(linkConsent, async (v: any[]) => updateLink(v));

    const {
      emailOrPhone,
      okToShare,
      inputOk,
      shareByEmailOrPhone,
      savingEmailOrPhone,
      deletingEmailOrPhone,
      deleteByEmailOrPhone,
      consentExistInResource
    } = shareByEmailOrPhoneSetup(props);

    watch(
      () => props.resource,
      val => {
        modal.value = !isEmpty(props.resource);

        if (val) {
          emailOrPhone.value = null;
          link.value = null;
        }
      }
    );

    watch(
      () => props.shareMoreStudies,
      val => {
        modal.value = val;

        if (val) {
          emailOrPhone.value = null;
          link.value = null;
        }
      }
    );

    const meilChange = ref(false);

    const handleCloseShareModal = () => {
      modal.value = false;
      context.emit('close-share-modal');
      meilChange.value = false;
    };

    watch(
      () => emailOrPhone.value,
      v => (meilChange.value = true)
    );

    watch(
      () => modal.value,
      val => {
        if (!val) {
          if (meilChange.value) {
            studyShareStore.commit(CLEAR_SELECTED_STUDIES);
          }
          handleCloseShareModal();
        }
      }
    );
    return {
      modal,
      loading,
      resources,
      emailOrPhone,
      okToShare,
      inputOk,
      shareByEmailOrPhone,
      savingEmailOrPhone,
      deletingEmailOrPhone,
      deleteByEmailOrPhone,

      link,
      linkNode,
      copyLink,
      generateLink,
      deleteLink,
      savingLink,
      handleGenerateLinkButton,
      removingLink,

      idRef,
      handleCloseShareModal,
      generateUuid,

      consentsListChips,
      isMoreResources,
      consentExistInResource,
      linkConsent
    };
  }
});
</script>

<style scoped>
.share-content {
  width: 50vw;
  color: var(--font-color);
}

.generate-link-info {
  color: var(--danger);
  text-align: center;
}

.consents {
  min-height: 50px;
}
.consents > * {
  margin-bottom: 2px;
  margin-right: 2px;
}

.share-input {
  flex-grow: 10;
}

.share-person {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  row-gap: 5px;
  transition: opacity 0.2s ease;
}
.share-person--disabled {
  opacity: 0;
}

.wrapper-alert {
  min-height: 30px;
}
.share-person-alert {
  grid-column: 1/-1;
  font-size: 0.8rem;
  color: var(--danger);
}

.share-area {
  padding: var(--app-base-space);
  border-radius: var(--main-radius);
  min-height: 90px;
}
.link {
  align-self: center;
  word-break: break-all;
  text-decoration: underline;
}
.ecg-share-loader {
  position: absolute;
  display: flex;
  flex-direction: row !important;
  align-items: center;
}
.share-header {
  color: var(--logo);
}
</style>
