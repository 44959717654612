<template>
  <div class="patient-header">
    <span class="flex-layout flex-nowrap">
      <slot></slot>
    </span>
    <span>
      <slot name="right"></slot>
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';

export default defineComponent({
  name: 'PatientHeader',
  props: {
    isSmaller: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
</script>

<style scoped lang="scss">
.patient-header {
  background-color: var(--primary);
  border-radius: 5px;
  padding: var(--app-base-space);
  color: var(--light-gray);
  border: 2px solid transparent;
  position: fixed;
  width: var(--main-panel-width);
  z-index: 1;
  top: 90px;

  display: grid;
  justify-content: stretch;
  align-items: center;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  transition: all 0.3s ease-in-out;

  max-height: 60px;

  &.isSmaller {
    box-shadow: var(--box-shadow);
    transform: translateY(-36px);
    @media (max-width: 1000px) {
      transform: translateY(-26px);
    }
  }

  @media (max-width: 1000px) {
    width: calc(100% - 2.5rem);
    top: 80px;
  }
}

.patient-header *:first-child {
  max-height: 40px;
}

.patient-header:hover {
  max-height: unset;

  &.patient-header *:first-child {
    max-height: unset;
  }
}
</style>
