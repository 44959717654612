<template>
  <button
    :class="['icon-button', blockShareButton ? 'icon-button--disabled' : '']"
    @click="
      $emit('share-by-id-and-type', {
        id: resource.id,
        resourceType: resource.resourceType
      })
    "
    :aria-label="$t('base.share.name')"
    :title="$t('base.share.name')"
    :disabled="blockShareButton"
    :id="shareButtonId"
  >
    <font-awesome-icon class="share-icon" icon="share-alt" :height="16" />
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Share',
  emits: ['share-by-id-and-type'],
  props: {
    resource: {
      type: Object as PropType<any>,
      required: true
    },
    blockShareButton: { required: false, default: false, type: Boolean },
    shareButtonId: {
      required: false,
      default: '0',
      type: String
    }
  }
});
</script>

<style scoped lang="scss">
.icon-button--disabled {
  color: var(--dark-gray) !important;
  cursor: default !important;
  &:hover {
    transform: scale(1) !important;
  }
}
</style>
