
import Share from '@/components/Commons/ResourcesPanel/Share/Share.vue';
import Download from '@/components/Commons/ResourcesPanel/Download/Download.vue';
import RecordOnCd from '@/components/Commons/ResourcesPanel/RecordOnCd/RecordOnCd.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { computed, onUnmounted, ref, watch } from 'vue';
import {
  SELECTED_STUDY,
  CLEAR_SELECTED_STUDIES
} from '@/store/studyShare/types';
import BaseCheckbox from '@/components/Base/BaseCheckbox.vue';
import Favourite from '@/components/Commons/ResourcesPanel/Favourite/Favourite.vue';
import useUser from '@/composables/useUser';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import useResource from '@/composables/useResource';

interface EmitInputOrEnterType {
  id: string;
  isChecked: boolean;
  value: boolean;
  ev: InputEvent | KeyboardEvent;
}

export interface InputInitValue {
  id: string;
}

export default {
  name: 'ResourcesPanel',
  emits: [
    'input',
    'share-by-id-and-type',
    'set-read-by-id',
    'toggled-favourite'
  ],
  components: {
    Favourite,
    BaseCheckbox,
    BaseButton,
    RecordOnCd,
    Download,
    Share
  },
  props: {
    resource: { required: true, type: Object },
    blockShareButton: { required: false, default: false, type: Boolean },
    readIds: { required: false, type: Array },
    shareButtonId: { required: false, type: String, default: '0' },
    /**
     *  admin - boolean
     *  if admin true - set some properties visible like 'share'
     */
    admin: { required: false, type: Boolean, default: false }
  },
  setup(
    props: any,
    context: {
      emit: (arg0: string, arg1?: InputInitValue | boolean) => void;
    }
  ) {
    const studyRead = ref(false);
    const studyShareStore = useStudyShareStore();
    const input = ref<HTMLInputElement>();
    const isChecked = ref(false);

    const handleSetRead = () => {
      context.emit('set-read-by-id', props.resource.id);
    };

    onUnmounted(() => {
      studyShareStore.commit(CLEAR_SELECTED_STUDIES);
      useResource().clearFavouriteList();
    });

    const checkIfResourceDisplayed = computed(() =>
      props.readIds.includes(props.resource.id)
    );

    const toggledFavourite = (isFavourite: boolean) => {
      context.emit('toggled-favourite', isFavourite);
    };

    watch(
      () => studyShareStore.getters[SELECTED_STUDY],
      val => {
        if (val.length === 0) {
          isChecked.value = false;
        }
      }
    );

    const handleInput = (ev: EmitInputOrEnterType) => {
      isChecked.value = !isChecked.value;

      console.log(ev);

      context.emit('input', {
        id: ev.id
      });
    };

    const isAdmin = computed(() => useUser().isAdmin());
    const isConsentSharedOnlyForMe = computed(
      () => !!props.resource?.isConsent
    );

    return {
      handleSetRead,
      studyRead,
      checkIfResourceDisplayed,
      handleInput,
      isChecked,
      input,
      toggledFavourite,
      isAdmin,
      isConsentSharedOnlyForMe
    };
  }
};
