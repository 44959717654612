
import { defineComponent, PropType } from 'vue';
import { Filter } from '@/components/Filters/types';
import BaseButton from '@/components/Base/BaseButton.vue';
import UsedFilter from '@/components/Filters/UsedFilter.vue';

export default defineComponent({
  components: { UsedFilter, BaseButton },
  props: {
    filters: {
      type: Object as PropType<Array<Filter>>,
      required: true
    }
  },
  name: 'UsedFilters'
});
