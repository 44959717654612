
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseButtonGroup from '@/components/Base/BaseButtonGroup.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import BaseLoader from '@/components/Base/BaseLoader.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseChip from '@/components/Base/BaseChip.vue';
import { generateUuid } from '@/utils/utils';
import shareByLinkSetup from '@/components/Commons/ResourcesPanel/Share/ShareByLink';
import shareByEmailOrPhoneSetup from '@/components/Commons/ResourcesPanel/Share/ShareByEmailOrPhone';
import shareResourcesSetup from '@/components/Commons/ResourcesPanel/Share/ShareResources';
import isEmpty from 'lodash/isEmpty';
import { CLEAR_SELECTED_STUDIES } from '@/store/studyShare/types';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import useConsent from '@/composables/useConsent';
import { replaceAll } from '@fhir/pixel-commons-js';

export default defineComponent({
  name: 'ShareModal',
  emits: ['close-share-modal'],
  components: {
    BaseChip,
    BaseInput,
    BaseLoader,
    EcgLoader,
    BaseButtonGroup,
    BaseButton,
    BaseModal
  },
  props: {
    resource: {
      type: Object as PropType<any>,
      required: true
    },
    moreResources: {
      type: Array as PropType<object[]>,
      required: false
    },
    shareMoreStudies: {
      type: Boolean,
      required: false
    },
    shareButtonId: {
      type: String,
      required: false,
      default: '0'
    }
  },
  setup(props, context) {
    const modal = ref(false);
    const studyShareStore = useStudyShareStore();

    const isMoreResources = ref<boolean>(false);
    const { loading, resources } = shareResourcesSetup(props);

    const consentsListChips: any = ref([]);
    const consentList = computed(() => useConsent().getListConsents());

    const linkConsent = computed(() =>
      consentsListChips.value.filter((f: any) => f.sharedFor.length === 0)
    );

    const {
      link,
      linkNode,
      copyLink,
      generateLink,
      deleteLink,
      savingLink,
      removingLink,
      getLink
    } = shareByLinkSetup(props);

    const handleGenerateLinkButton = () => {
      const moreResources = !!props.moreResources?.length;
      generateLink(moreResources);
    };

    watch(
      consentList,
      v => {
        consentsListChips.value = useConsent().findConsentsRelatedToDocument(
          props.resource
        );
      },
      { deep: true, immediate: true }
    );

    watch(
      modal,
      v => {
        if (v) {
          let resource;
          const resourcesLength = props.moreResources?.length || 0;
          if (resourcesLength === 1 && props.moreResources != undefined) {
            resource = props.moreResources[0];
          } else if (resourcesLength > 1 && props.moreResources != undefined) {
            resource = [];
          } else {
            resource = props.resource;
          }
          consentsListChips.value = useConsent().findConsentsRelatedToDocument(
            resource
          );
        }
      },
      { deep: true, immediate: true }
    );

    const idRef = ref(generateUuid());

    watch(props, value => {
      const resourcesLength = value.moreResources?.length || 0;
      resourcesLength > 1
        ? (isMoreResources.value = true)
        : (isMoreResources.value = false);
    });

    const updateLink = (value: any[]) => {
      if (value && value.length > 0) {
        const lc = value[0];
        if (lc && lc.documentId) {
          const newLink = getLink(replaceAll(lc.documentId, 'Consent/', ''));
          link.value = newLink;
        }
      }
    };

    watch(linkConsent, async (v: any[]) => updateLink(v));

    const {
      emailOrPhone,
      okToShare,
      inputOk,
      shareByEmailOrPhone,
      savingEmailOrPhone,
      deletingEmailOrPhone,
      deleteByEmailOrPhone,
      consentExistInResource
    } = shareByEmailOrPhoneSetup(props);

    watch(
      () => props.resource,
      val => {
        modal.value = !isEmpty(props.resource);

        if (val) {
          emailOrPhone.value = null;
          link.value = null;
        }
      }
    );

    watch(
      () => props.shareMoreStudies,
      val => {
        modal.value = val;

        if (val) {
          emailOrPhone.value = null;
          link.value = null;
        }
      }
    );

    const meilChange = ref(false);

    const handleCloseShareModal = () => {
      modal.value = false;
      context.emit('close-share-modal');
      meilChange.value = false;
    };

    watch(
      () => emailOrPhone.value,
      v => (meilChange.value = true)
    );

    watch(
      () => modal.value,
      val => {
        if (!val) {
          if (meilChange.value) {
            studyShareStore.commit(CLEAR_SELECTED_STUDIES);
          }
          handleCloseShareModal();
        }
      }
    );
    return {
      modal,
      loading,
      resources,
      emailOrPhone,
      okToShare,
      inputOk,
      shareByEmailOrPhone,
      savingEmailOrPhone,
      deletingEmailOrPhone,
      deleteByEmailOrPhone,

      link,
      linkNode,
      copyLink,
      generateLink,
      deleteLink,
      savingLink,
      handleGenerateLinkButton,
      removingLink,

      idRef,
      handleCloseShareModal,
      generateUuid,

      consentsListChips,
      isMoreResources,
      consentExistInResource,
      linkConsent
    };
  }
});
