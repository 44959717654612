<template>
  <div class="filter-container">
    <template v-if="filter.description === $t('admin.filters.patients')">
      <span>{{ filter.description }}:</span>
      <span
        v-for="(name, index) in filter.patientsNames"
        :key="name"
        class="filter-container__patients"
      >
        {{ filter.patientsNames.length === index + 1 ? name : name + ',' }}
      </span>
    </template>
    <template v-else-if="filter.description === $t('admin.filters.birthDates')">
      <span>{{ filter.description }}:</span>
      <span
        v-for="(date, index) in filter.birthDates"
        :key="date"
        class="filter-container__patients"
      >
        {{ filter.birthDates.length === index + 1 ? date : date + ',' }}
      </span>
    </template>
    <template v-else-if="filter.description === $t('admin.filters.pesel')">
      <span>{{ filter.description }}:</span>
      <span
        v-for="(pesel, index) in filter.pesels"
        :key="pesel"
        class="filter-container__patients"
      >
        {{ filter.pesels.length === index + 1 ? pesel : pesel + ',' }}
      </span>
    </template>
    <span v-else>{{ filter.description }}</span>
    <button
      class="icon-button icon-button-chips ml"
      @click="filter.remove()"
      :aria-label="$t('base.removeFilter')"
    >
      <font-awesome-icon icon="times" :height="14"></font-awesome-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Filter } from '@/components/Filters/types';
import BaseButton from '@/components/Base/BaseButton.vue';

export default defineComponent({
  components: { BaseButton },
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: true
    }
  },
  name: 'UsedFilter'
});
</script>

<style scoped lang="scss">
.filter-container {
  display: flex;
  align-items: center;
  padding: 4px var(--app-base-space) 4px var(--app-base-space);
  border-radius: 16px;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  font-size: 0.875rem;
  &__patients {
    margin-left: 3px;
  }
}

.filter-container * {
  color: var(--light);
}

.icon-button-chips {
  padding-right: 0;
  padding-top: 1px;
}
</style>
