
import { defineComponent, watch, onMounted, onUnmounted, onUpdated } from 'vue';
import PatientHeader from '@/components/Patient/PatientHeader.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import Pager from '@/components/Commons/Pager.vue';
import UsedFilters from '@/components/Filters/UsedFilters.vue';
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import ResourcesPanel from '@/components/Commons/ResourcesPanel/ResourcesPanel.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import ShareModal from '@/components/Commons/ResourcesPanel/Share/ShareModal/ShareModal.vue';
import { CLEAR_SELECTED_STUDIES } from '@/store/studyShare/types';
import PatientAndAdmin from '@/components/PatientAndAdmin/PatientAndAdmin';
import EmptyInfo from '@/components/Commons/EmptyInfo/EmptyInfo.vue';
import PatientTages from '@/components/Patient/PatientTags';
import SharedBadge from '@/components/Commons/SharedBadge.vue';
import useConsent from '@/composables/useConsent';
import { useStore as useStudyShareStore } from '@/store/studyShare';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';

export default defineComponent({
  name: 'ForPatientContent',
  components: {
    SharedBadge,
    EmptyInfo,
    ShareModal,
    BaseButton,
    ResourcesPanel,
    FhirComponentsRouter,
    UsedFilters,
    Pager,
    ExpandablePanel,
    EcgLoader,
    PatientHeader
  },
  setup() {
    const {
      search,
      isSmaller,
      usedFilters,
      selectedStudy,
      shareMoreStudies,
      filteredResourcesLength,
      page,
      perPage,
      loading,
      pagedResourcesOnlyDocuments,
      checkIfHeaderCanBeBolded,
      getActiveHeader,
      admin,
      readIds,
      handleGetCheckbox,
      shareDialogId,
      handleCloseShareModal,
      handleOneResource,
      getMoreResourcesToShare,
      activeFocused,
      shareIdAndType,
      dateFilter,
      patientFilter,
      sharedFilter,
      getterSearchText
    } = PatientAndAdmin();
    const studyShareStore = useStudyShareStore();

    const refreshTime = Number(localStorage.getItem('refreshTime')) || 0;
    let refreshInterval: undefined | number;

    const clearSelectedStudies = () => {
      studyShareStore.commit(CLEAR_SELECTED_STUDIES);
    };

    onMounted(async () => {
      await useConsent().loadListConsents();
    });

    onUnmounted(() => clearInterval(refreshInterval));

    onUpdated(() => {
      clearInterval(refreshInterval);
      if (refreshTime > 0) {
        refreshInterval = setInterval(() => {
          search();
        }, refreshTime * 1000);
      }
    });

    const { addDisplayedResourceTag } = PatientTages();

    watch(
      sharedFilter,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );
    watch(
      dateFilter,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );
    watch(
      patientFilter,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );
    watch(
      getterSearchText,
      (v, o) => {
        if (v != o) {
          search();
        }
      },
      { immediate: true }
    );

    return {
      search,
      isSmaller,
      usedFilters,
      selectedStudy,
      shareMoreStudies,
      filteredResourcesLength,
      page,
      sharedFilter,
      perPage,
      loading,
      pagedResourcesOnlyDocuments,
      checkIfHeaderCanBeBolded,
      getActiveHeader,
      admin,
      readIds,
      handleGetCheckbox,
      shareDialogId,
      handleCloseShareModal,
      handleOneResource,
      clearSelectedStudies,
      getMoreResourcesToShare,
      activeFocused,
      shareIdAndType,
      addDisplayedResourceTag
    };
  }
});
