<template>
  <router-link
    class="icon-button"
    :aria-label="$t('base.download')"
    :title="$t('base.download')"
    target="_blank"
    :to="{
      name: 'GeneratePdf',
      params: { resourceType: resource.resourceType, resourceId: resource.id }
    }"
  >
    <font-awesome-icon icon="download" :height="16"></font-awesome-icon>
  </router-link>
</template>

<script lang="ts">
export default {
  name: 'Download',
  props: {
    resource: { required: true, type: Object }
  }
};
</script>

<style scoped></style>
