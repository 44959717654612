
import { defineComponent, PropType } from 'vue';
import { Filter } from '@/components/Filters/types';
import BaseButton from '@/components/Base/BaseButton.vue';

export default defineComponent({
  components: { BaseButton },
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: true
    }
  },
  name: 'UsedFilter'
});
