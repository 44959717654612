<template>
  <div
    class="pager-container"
    :class="{ 'pager-container-negative': negative }"
    v-show="pages > 0"
  >
    <button
      ref="pagerButton"
      class="pager-button"
      :aria-label="$t('base.pagerOptions')"
      @click="showSettings = !showSettings"
    >
      {{ itemsFrom + 1 }} - {{ itemsTo }} / {{ all }}
    </button>
    <button
      class="pager-button"
      :aria-label="$t('base.prevPage')"
      :disabled="!prevPageAvailable"
      @click="prevPage"
    >
      <font-awesome-icon icon="angle-left" :height="16"></font-awesome-icon>
    </button>
    <button
      class="pager-button"
      :aria-label="$t('base.nextPage')"
      :disabled="!nextPageAvailable"
      @click="nextPage"
    >
      <font-awesome-icon icon="angle-right" :height="16"></font-awesome-icon>
    </button>
    <transition name="fade">
      <div
        class="pager-popup-fader"
        v-show="showSettings"
        @click="showSettings = false"
      ></div>
    </transition>
    <transition name="fade">
      <div
        @keydown.esc="showSettings = false"
        id="pagerSettings"
        class="pager-popup"
        v-show="showSettings"
        role="dialog"
        aria-modal="true"
      >
        <FocusLoop>
          <button
            ref="pagerSettings"
            @click="showSettings = false"
            class="pager-button close-button"
            :aria-label="$t('base.close')"
          >
            <font-awesome-icon icon="times" :height="16"></font-awesome-icon>
          </button>
          <div style="text-align: center;margin-bottom: 20px;">
            {{ $t('base.perPage') }}
          </div>
          <RadioGroup direction="horizontal" style="justify-content: center">
            <RadioButton
              :value="item.id"
              v-for="(item, i) in perPages"
              :key="i"
              :label="item.label"
              :model-value="perPage"
              @update:modelValue="setPages"
              :name="uuid"
              :first="i === 0"
            ></RadioButton>
          </RadioGroup>
          <template v-if="pages > 1">
            <div style="margin-top:20px; text-align: center">
              {{ $t('base.gotoPage') }}
            </div>
            <div
              class="flex-layout"
              style="margin-top:10px; justify-content: center"
            >
              <input
                v-model="gotoPage"
                type="number"
                :min="1"
                :max="pages"
                :step="1"
                @keydown.enter="setPage(gotoPage)"
                class="number-input"
              />
              <span style="margin-left:10px;">
                {{ $t('base.from') }} {{ pages }}
              </span>
              <button
                @click="setPage(gotoPage)"
                class="pager-button color-button ml"
                :aria-label="$t('base.gotoPage')"
              >
                <font-awesome-icon
                  icon="arrow-right"
                  :height="16"
                ></font-awesome-icon>
              </button>
            </div>
          </template>
        </FocusLoop>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  watch,
  ref,
  onMounted,
  nextTick
} from 'vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import { generateUuid } from '@/utils/utils';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';

export default defineComponent({
  name: 'Pager',
  components: { RadioButton, RadioGroup, FocusLoop },
  props: {
    all: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true,
      default: 1
    },
    perPage: {
      type: Number,
      required: true,
      default: 10
    },
    negative: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:page', 'update:perPage'],
  setup(props, { emit }) {
    const pages = computed(() => Math.ceil(props.all / props.perPage));
    const nextPageAvailable = computed(() => props.page < pages.value);
    const prevPageAvailable = computed(() => props.page > 1);

    const nextPage = () => {
      if (nextPageAvailable.value) {
        emit('update:page', props.page + 1);
      }
    };

    const prevPage = () => {
      if (prevPageAvailable.value) {
        emit('update:page', props.page - 1);
      }
    };

    const itemsFrom = computed(() => (props.page - 1) * props.perPage);
    const itemsTo = computed(() =>
      itemsFrom.value + props.perPage < props.all
        ? itemsFrom.value + props.perPage
        : props.all
    );

    const showSettings = ref(false);
    const setPages = (perPage: number) => {
      const pages = Math.ceil(props.all / perPage);
      if (props.page > pages) {
        emit('update:page', pages);
      }
      emit('update:perPage', perPage);
      showSettings.value = false;
    };

    const pagerButton = ref<HTMLElement>();
    const pagerSettings = ref<HTMLElement>();

    watch(showSettings, v => {
      if (v) {
        nextTick(() => pagerSettings.value?.focus());
      } else {
        nextTick(() => pagerButton.value?.focus());
      }
    });

    const perPages = computed(() => [
      { id: 10, label: '10' },
      { id: 20, label: '20' },
      { id: 50, label: '50' },
      { id: 100, label: '100' }
    ]);

    const uuid = computed(() => generateUuid());

    const gotoPage = ref(1);
    watch(gotoPage, v => {
      if (gotoPage.value > pages.value) {
        gotoPage.value = pages.value;
      }
      if (gotoPage.value <= 0) {
        gotoPage.value = 1;
      }
    });
    watch(pages, v => {
      if (gotoPage.value > v) {
        gotoPage.value = v;
      }
    });

    const setPage = (page: number) => {
      emit('update:page', Number(page));
      showSettings.value = false;
    };

    return {
      pages,
      nextPageAvailable,
      prevPageAvailable,
      nextPage,
      prevPage,
      itemsFrom,
      itemsTo,
      setPages,
      showSettings,
      perPages,
      uuid,
      pagerButton,
      pagerSettings,
      gotoPage,
      setPage
    };
  }
});
</script>

<style scoped lang="scss">
.pager-container {
  color: var(--font-color);
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 5px;
  border-radius: 5px;
}

.pager-container-negative {
  & .pager-button {
    color: var(--main-color);
  }
}
.pager-container > *:not(:first-child) {
  margin-left: 10px;
}
.pager-button {
  cursor: pointer;
  background: transparent;
  border: 1px solid transparent;
  color: var(--main-color);
  padding: 0 8px;
  font-size: 0.875rem;
  &:focus {
    outline: none;
    border: 1px solid transparent;
  }
  &:focus-visible {
    border: 1px solid var(--accent);
  }
}
.pager-button:disabled {
  color: var(--disabled);
}

.close-button {
  color: var(--main-color) !important;
  position: absolute;
  right: 3px;
}

.color-button {
  color: var(--main-color) !important;
}

.pager-popup-fader {
  background-color: var(--modal-bg);
  position: fixed;
  left: -10px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 4;
}

.pager-popup {
  border: solid 2px transparent;
  position: absolute;
  background-color: var(--light);
  padding: 5px;
  border-radius: 5px;
  top: 100%;
  right: -10px;
  width: 300px;
  display: grid;
  z-index: 5;
  box-shadow: 0px 0px 6px 1px #4a4a4a;
}

.number-input {
  width: 80px;
}
</style>
