
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Share',
  emits: ['share-by-id-and-type'],
  props: {
    resource: {
      type: Object as PropType<any>,
      required: true
    },
    blockShareButton: { required: false, default: false, type: Boolean },
    shareButtonId: {
      required: false,
      default: '0',
      type: String
    }
  }
});
