
import { computed, ref } from 'vue';
import { url } from '@/components/Patient/PatientUtils';
import { System, Tag, Tags } from '@/components/Patient/PatientTags';
import { useI18n } from 'vue-i18n';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import useUser from '@/composables/useUser';
import useResource from '@/composables/useResource';

export default {
  name: 'Favourite',
  props: {
    resource: { required: true, type: Object }
  },
  emits: ['toggled-favourite'],
  setup(props: any, context: { emit: (arg0: string, arg1: boolean) => void }) {
    const { t } = useI18n();

    const favourite = computed(() =>
      props?.resource?.meta?.tag?.find(
        (f: any) =>
          f.system === System.patientPortalFavouriteSystem &&
          f.code === useUser().getUserName()
      )
    );

    const isFavourite = ref(favourite.value != null);

    const toggleFavourite = async (): Promise<any> => {
      try {
        const tag: Tag = {
          system: System.patientPortalFavouriteSystem,
          code: `${useUser().getUserName()}`
        };
        const tags: Tags = {
          tag: [tag]
        };
        const constructAddUrl = url(props.resource)
          ? `${url(props.resource)}/$meta-add`
          : '';
        const constructDelUrl = url(props.resource)
          ? `${url(props.resource)}/$meta-delete`
          : '';

        if (constructAddUrl && constructDelUrl) {
          await useResource().addTag(
            isFavourite.value ? constructDelUrl : constructAddUrl,
            tags
          );
          useNotification().addNotification(
            NotificationType.SUCCESS,
            '',
            t(
              isFavourite.value
                ? 'admin.results.notAsFavourite'
                : 'admin.results.asFavourite'
            )
          );

          isFavourite.value = !isFavourite.value;

          context.emit('toggled-favourite', isFavourite.value);

          useResource().changeFavourite(props.resource.id, isFavourite.value);
        } else {
          console.warn('Construct url is not correct');
          useNotification().addNotification(
            NotificationType.ERROR,
            '',
            t('admin.results.errorFavourite')
          );
        }
      } catch (e) {
        useNotification().addNotification(
          NotificationType.ERROR,
          '',
          t('admin.results.errorFavourite')
        );
      }
    };

    return {
      isFavourite,
      toggleFavourite
    };
  }
};
