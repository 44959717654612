<template>
  <div class="filters-container">
    <UsedFilter :filter="f" v-for="(f, i) in filters" :key="i"></UsedFilter>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Filter } from '@/components/Filters/types';
import BaseButton from '@/components/Base/BaseButton.vue';
import UsedFilter from '@/components/Filters/UsedFilter.vue';

export default defineComponent({
  components: { UsedFilter, BaseButton },
  props: {
    filters: {
      type: Object as PropType<Array<Filter>>,
      required: true
    }
  },
  name: 'UsedFilters'
});
</script>

<style scoped lang="scss">
.filters-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .filter-container {
    margin: 1px;
  }
}

.filters-container > * {
  margin-left: 3px;
}
</style>
