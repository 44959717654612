<template>
  <button
    class="icon-button"
    :aria-label="$t('base.recordOnCd')"
    :title="$t('base.recordOnCd')"
    disabled
  >
    <font-awesome-icon icon="compact-disc" :height="16"></font-awesome-icon>
  </button>
</template>

<script>
export default {
  name: 'RecordOnCd',
  resource: { required: true, type: Object }
};
</script>

<style scoped>
.icon-button:disabled {
  color: var(--light-gray);
  cursor: default;
  transform: scale(1);
}
</style>
