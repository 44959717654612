import { computed, ref } from 'vue';
import { ConsentWrapper } from '@/components/Commons/ResourcesPanel/Share/ShareTypes';
import { useI18n } from 'vue-i18n';

export const resources = ref<ConsentWrapper[]>([]);

export default (props: any) => {
  const { t } = useI18n();

  const loading = ref<boolean>(false);

  // const searchShares = async (): Promise<any> => {
  //   try {
  //     resources.value = [];
  //     loading.value = true;
  //     // await sleep(2000, false);
  //     const data =
  //       props.moreResources.length !== 0
  //         ? await Promise.allSettled(
  //             props.moreResources.map((it: any) =>
  //               useConsent().loadListConsentsForResource(it.resource)
  //             )
  //           )
  //         : await useConsent().loadListConsentsForResource(props.resource);
  //
  //     const consentList = computed(() => useConsent().getListConsents());
  //
  //     const mapped =
  //       props.moreResources.length !== 0
  //         ? uniqBy(
  //             consentList.value
  //               .filter((it: any) => it.status === 'fulfilled')
  //               .map((it: any) => it.value.data)
  //               .flat(),
  //             'id'
  //           )
  //         : consentList.value;
  //
  //     if (mapped !== null) {
  //       const r: any[] = cloneDeep(mapped);
  //       resources.value = r
  //         .filter(f => f.resourceType === 'Consent')
  //         .map(m => {
  //           let dateFrom = '';
  //           let dateTo = '';
  //           if (m.period != null) {
  //             if (m.period.start != null) {
  //               dateFrom = dateMinutes(m.period.start);
  //             }
  //             if (m.period.end != null) {
  //               dateTo = dateMinutes(m.period.end);
  //             }
  //           }
  //           const email = 'test@trerer.pl';
  //           const phone = '234-564-234';
  //           const ret = {
  //             consentId: `${Math.random()}`,
  //             documentId: `${m.resourceType}/${m.id}`,
  //             dateFrom,
  //             dateTo,
  //             email: email,
  //             phone: phone,
  //             range: () => {
  //               let ret = dateFrom;
  //               ret = append(ret, dateTo, ' - ');
  //               return ret;
  //             },
  //             whom: () => {
  //               let ret = email;
  //               ret = append(ret, phone, ', ');
  //               return ret;
  //             }
  //           };
  //           return ret;
  //         });
  //     }
  //   } catch (e) {
  //     useNotification().addNotification(
  //       NotificationType.ERROR,
  //       '',
  //       t('base.share.listError')
  //     );
  //     console.error(t('base.share.listError'), e);
  //   } finally {
  //     loading.value = false;
  //   }
  // };

  return {
    loading,
    resources
  };
};
