
import { computed, defineComponent, watch } from 'vue';

export default defineComponent({
  name: 'PatientHeader',
  props: {
    isSmaller: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
