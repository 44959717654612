import jp from 'jsonpath';
import { computed, inject, ref } from 'vue';
import { day, getDateFromResource, ok } from '@fhir/pixel-commons-js/src/index';
import { useI18n } from 'vue-i18n';
import useConsent from '@/composables/useConsent';
import { ShareEnum } from '@/composables/types/consent';
import useCommunicator from '@/composables/useCommunicator';
import { NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE } from '@/composables/types/communicator';
import { getDescriptionFromResource } from '@/components/Patient/PatientUtils';
import useUser from '@/composables/useUser';

const EMAIL_RE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_RE = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,4})(?: *x *(\d+))?\s*$/;

export default (props: any) => {
  const { t, locale } = useI18n();

  const emailOrPhone = ref<string | null>(null);

  const validateEmail = (email: string): boolean => {
    const re = EMAIL_RE;
    return re.test(email.toLowerCase());
  };

  const validatePhone = (phone: string): boolean => {
    const re = PHONE_RE;
    return re.test(phone.toLowerCase());
  };

  const inputOk = computed(() => {
    if (emailOrPhone.value != null && ok(emailOrPhone.value)) {
      return (
        validateEmail(emailOrPhone.value) || validatePhone(emailOrPhone.value)
      );
    }
    return true;
  });

  const consentExistInResource = computed(() =>
    useConsent()
      .actorsForDocumentConsentsOnResource(props.resource)
      .map(item => item.whom.toString())
      .filter(item => item)
      .includes(emailOrPhone.value ?? '')
  );

  const okToShare = computed(
    () =>
      ok(emailOrPhone.value) && inputOk.value && !consentExistInResource.value
  );

  const savingEmailOrPhone = ref<boolean>(false);

  const getParamsForNotification = (resource: any, receiver: string): any => {
    if (resource != null) {
      const tenant = jp.value(
        resource,
        "meta.tag[?(@.system=='urn:tenant')].code"
      );

      return {
        id: resource.id,
        type: resource.resourceType,
        LANG: locale.value,
        SOURCE_USER_RADID: useUser().getUserName(),
        SOURCE_USER_LASTNAME: '',
        SOURCE_USER_FIRSTNAME: useUser().getUserName(),
        NOTIFICATION_RECEIVER_RAD_ID: receiver,
        studyDescription: `${day(
          getDateFromResource(resource)
        )} ${getDescriptionFromResource(resource) || ''}`,
        //DOCUMENT: resource,
        //DOC_ID: `${tenant || ''}/${resource.resourceType || ''}/${resource.id ||''}`
        DOC_ID: `${resource.resourceType || ''}/${resource.id || ''}`
      };
    }
    return {};
  };

  const shareByEmailOrPhone = async () => {
    if (okToShare.value) {
      try {
        savingEmailOrPhone.value = true;
        const inputValue = JSON.parse(JSON.stringify(emailOrPhone.value));

        //TODO get username for email or phone

        const consentShareValue = validateEmail(inputValue)
          ? ShareEnum.email
          : ShareEnum.phone;

        if (props.shareMoreStudies) {
          if (props.moreResources.length > 0) {
            try {
              await Promise.all(
                props.moreResources.map((r: any) =>
                  useCommunicator().addMessage(
                    NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE,
                    [inputValue],
                    getParamsForNotification(r, inputValue)
                  )
                )
              );
            } catch (e) {
              console.error('Błąd dodawania notyfikacji', e);
            }

            await Promise.all(
              props.moreResources.map(
                async (resource: any) =>
                  await useConsent().addConsentBy(
                    consentShareValue,
                    inputValue,
                    resource
                  )
              )
            );
          }
        } else {
          if (props.resource) {
            await useConsent().addConsentBy(
              consentShareValue,
              inputValue,
              props.resource
            );
            try {
              await useCommunicator().addMessage(
                NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE,
                [inputValue],
                getParamsForNotification(props.resource, inputValue)
              );
            } catch (e) {
              console.error('Błąd dodawania notyfikacji', e);
            }
          }
        }

        emailOrPhone.value = null;
      } catch (e) {
        // error(notificationStore, e, t('base.share.shareError'));
        console.error(t('base.share.shareError'), e);
      } finally {
        savingEmailOrPhone.value = false;
        // const studyShareStore = useStudyShareStore();
        // console.log(studyShareStore);
        // await studyShareStore.dispatch(CLEAR_SELECTED_STUDIES);
      }
    }
  };

  // const confirm = inject<any>('confirm');

  const deletingEmailOrPhone = ref<boolean>(false);
  const deleteByEmailOrPhone = async (consent: any) => {
    deletingEmailOrPhone.value = true;
    await useConsent().removeConsentByEmailOrPhone(consent, props.resource);
    deletingEmailOrPhone.value = false;
  };

  return {
    emailOrPhone,
    okToShare,
    inputOk,
    shareByEmailOrPhone,
    savingEmailOrPhone,
    deletingEmailOrPhone,
    deleteByEmailOrPhone,
    consentExistInResource
  };
};
